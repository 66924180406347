.container {
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: visible;
    background: #153f3a;
}

.formHolder {
    width: 50%;
    height: auto;
    background: #59a87d;
    border-radius: 10px;
    box-shadow: 3px 3px 20px 3px #0a0031;
    margin-top: 20vh;
}
.header {
    background: #153f3a;
    display: flex;
    gap: 2px;
}

.btnchange {
    outline: none;
    border: none;
    width: 50%;
    background: #16453f;
    border-bottom: 2px solid #153f3a;
    border-radius: 10px 10px 0 0;
    padding: 8px;
    color: #f0debb;
}
.btnchange.active {
    background: #59a87d;
}
.btnchange:hover {
    background: #5ca87e;
}

.title {
    font-size: 40px;
    justify-self: center;
    color: #f0debb;
    margin: 5px;
    border-bottom: 2px solid #153f3a;
    width: 100%;
    text-align: center;
}

#cepInput {
    display: flex;
    width: 90%;
    justify-self: center;
}

#adressInput {
    display: flex;
    width: 90%;
    justify-self: center;
    flex-direction: column;
}
.inputAdress {
    width: 96.8%;
}

.input {
    background-color: transparent;
    border: 0;
    font-size: 20px;
    color: #f0debb;
    outline: none;
    margin-right: 8px;
}
#adressInput .buttonSearch {
    background: #f0debb;
    margin-bottom: 12px;
    padding: 6px 10px;
}
.buttonSearch:hover {
    transform: scale(1.04);
}
div#cepInput .buttonSearch:hover {
    transform: scale(1.2);
}
input::placeholder {
    color: #541734;
}
.inputAdress, select.selectInput {
    background-color: #f0debb;
    border: 0;
    font-size: 20px;
    color: #541734;
    outline: none;
    margin: 12px 0;
    line-height: 25px;
    height: 35px;
    border-radius: 2px;
    padding: 5px 10px;
    width:  calc(100% - 20px);;
}
.marg-rig {
    margin-right: 10px;
}
select.selectInput option {
    background-color: #541734;
    border: 0;
    font-size: 18px;
    color: #f0debb;
    outline: none;
    margin: 10px;
}
select.selectInput {
    width: 100%;
}
.buttonSearch {
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    align-items:center;
    cursor: pointer;
    transition: transform 0.5s;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* border-radius: 8px; */
    background-color: transparent;
    color: #541734;
    border-top: 2px solid #153f3a;
}

.main .h2 {
    margin: 16px 0;
    font-size: 39px;
}

.main .span {
    margin-bottom: 16px;
    font-weight: bold;
}
main.adressList h2 {
    color: #f0debb;
    text-align: center;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 10px;
}
@media (max-width: 620px) {

    .formHolder{
        width: 100%;
        border-radius: none;
        margin-top: 0;
        min-height: 100vh;
    }

    .header button {
        border-radius: 0;
    }
}
