.adressList>div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.container .element-adress-handle {
    height: 100%;
    margin-top: 5px;
    border-top: 2px solid #153f3a;
    padding-top: 10px;
}
main.adressList div > div {
    padding: 8px;
}

main.adressList div > div h3 {
    font-size: 11px;
    line-height: 20px;
}

main.adressList div > div p {
    font-size: 12px;
}

main.adressList div > div h3, main.adressList div > div p {
    color: #541734;
}
@media (max-width: 620px) {

    .adressList>div {
        display: grid;
        grid-template-columns: 1fr;
    }

    .container .element-adress-handle {
        height: 100%;
        padding: 10px;
    }

    main.adressList div > div h3 {
        font-size: 14px;
        line-height: 22px;
    }
    
    main.adressList div > div p {
        font-size: 16px;
        line-height: 20px;
    }
}